import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import SectionTitle from '../SectionTitle';
import * as Styled from './ImageSection.styles';
import Container from '../Container';
import {useStyledDarkMode} from '../../hooks/useStyledDarkMode';

const ImageSection = ({title, subtitle, image, darkModeImage}) => {
  const {isDark} = useStyledDarkMode();
  const _image = image && image.file ? image.file.url : null;
  const _darkModeImage =
    darkModeImage && darkModeImage.file
      ? darkModeImage.file.url
      : null;
  return (
    <Styled.Wrapper>
      <Container>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          alignment="left"
        />
        <Styled.Image>
          {isDark ? (
            <img src={_darkModeImage || _image} alt={title} loading="lazy" />
          ) : (
            <img src={_image} alt={title} loading="lazy" />
          )}
        </Styled.Image>
      </Container>
    </Styled.Wrapper>
  );
};

ImageSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  darkModeImage: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

export default ImageSection;

export const query = graphql`
  fragment ImageSection on ContentfulImageSection {
    imageSectionTitle: title
    imageSectionSubtitle: subtitle
    image {
      file {
        url
      }
    }
    darkModeImage {
      file {
        url
      }
    }
  }
`;
