import styled from 'styled-components';

export const Wrapper = styled.section``;

export const Image = styled.div`
  margin-top: 30px;
  img {
    width: 100%;
    object-fit: contain;
  }
`;
