import styled from 'styled-components';
import media from '../../shared/media';

export const Wrapper = styled.div`
  padding: 40px 0;

  ${media.tablet} {
    padding: 60px 0;
  }
`;

export const ConnectorList = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 40px 0 0;

  ${media.tablet} {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
`;

export const Connector = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  padding: 20px 40px;

  ${media.tablet} {
    flex-basis: 33.333%;
    padding: 20px 30px;
  }
`;

export const ConnectorLogo = styled.div`
  img {
    width: 100%;
    height: 80px;
    object-fit: contain;

    ${media.tablet} {
      height: 115px;
    }
  }
`;
