import {useStaticQuery, graphql} from 'gatsby';

const useConnectorsList = () => {
  const {allContentfulConnector} = useStaticQuery(graphql`
    query Connectors {
      allContentfulConnector {
        edges {
          node {
            title
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  return allContentfulConnector.edges.map((edge) => edge.node);
};

export default useConnectorsList;
