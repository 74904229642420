import React from 'react';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import TextImage from '../components/TextImage';
import IconList from '../components/IconList';

import CtaSection from '../components/CtaSection';
import {graphql} from 'gatsby';
import SectionMapper from '../components/SectionMapper/SectionMapper';
import ImageSection from '../components/ImageSection';
import ConnectorSection from '../components/ConnectorsSection';

import {buildNavigationMenu} from '../utils/navigation';

const ServicesDefaultTemplate = ({pageContext, data}) => {
  const {generalSettings} = pageContext;
  const {sections, title, slug, metaTitle, metaDescription, metaKeywords} =
    data.service;

  const companyAddress = {
    address: generalSettings.companyAddress,
    email: generalSettings.companyEmail,
  };

  const metaContent = {
    metaTitle,
    metaDescription: metaDescription?.childMarkdownRemark?.html,
    metaKeywords,
  };

  const headerNavigation = buildNavigationMenu(
    generalSettings.headerMenu.items
  );
  const footerNavigation = buildNavigationMenu(
    generalSettings.footerMenu.items
  );

  return (
    <Layout
      headerNavigation={headerNavigation}
      footerNavigation={footerNavigation}
      template="services"
      title={title}
      companyAddress={companyAddress}
      metaContent={metaContent}
      slug={slug}
    >
      {(sections || []).map((section, key) => {
        if (section.__typename === 'ContentfulHero') {
          return (
            <Hero key={key} backgroundImage={section.backgroundImage}>
              <Hero.Title
                dangerouslySetInnerHTML={{
                  __html: section.title.childMarkdownRemark.html,
                }}
              />
              {section.content && (
                <Hero.Content
                  dangerouslySetInnerHTML={{
                    __html: section.content.childMarkdownRemark.html,
                  }}
                />
              )}
            </Hero>
          );
        }
        if (section.__typename === 'ContentfulSection') {
          return (
            <SectionMapper
              type={section.type}
              sectionData={section}
              key={key}
            />
          );
        }
        if (section.__typename === 'ContentfulClientsSection') {
          {
            /* this part is going to be updated later */
          }
          let type = 'clients';
          if (section.testimonials) type = 'testimonials-list';
          return (
            <SectionMapper
              type={type}
              sectionData={section}
              key={key}
            />
          );
        }
        if (section.__typename === 'ContentfulInsightsSection') {
          {
            /* this part is going to be updated later */
          }
          let type = section.type ? section.type : 'insights';
          return (
            <SectionMapper
              type={type}
              sectionData={section}
              key={key}
            />
          );
        }
        if (section.__typename === 'ContentfulTextImage') {
          return (
            <TextImage
              title={section.textImageTitle}
              subtitle={section.textImageSubtitle}
              content={section.content.childMarkdownRemark.html}
              image={section.image}
              imageAlignment={section.imageAlignment}
              key={key}
              ctaLink={section.ctaLink}
              ctaText={section.ctaText}
            />
          );
        }
        if (section.__typename === 'ContentfulImageSection') {
          return (
            <ImageSection
              subtitle={section.imageSectionTitle}
              title={section.imageSectionSubtitle}
              image={section.image}
              darkModeImage={section.darkModeImage}
              key={key}
            />
          );
        }
        if (section.__typename === 'ContentfulConnectorsSection') {
          return (
            <ConnectorSection
              subtitle={section.connectorsSectionTitle}
              title={section.connectorsSectionSubtitle}
              key={key}
            />
          );
        }
        if (section.__typename === 'ContentfulIconList') {
          return (
            <IconList
              title={section.iconListTitle}
              subtitle={section.iconListSubtitle}
              items={section.items.map((item) => ({
                title: item.title,
                icon: item.icon.file.url,
                description: item.description
                  ? item.description.childMarkdownRemark.html
                  : '',
              }))}
              style={section.style}
              rowsDesktop={section.rowsDesktop}
              rowsMobile={section.rowsMobile}
              key={key}
            />
          );
        }
        if (section.__typename === 'ContentfulCtaSection') {
          return (
            <CtaSection
              key={key + 10}
              title={section.ctaSectionTitle}
              actionLink={section.actionLink}
              actionText={section.actionText}
            />
          );
        }
        return null;
      })}
    </Layout>
  );
};

export default ServicesDefaultTemplate;

export const query = graphql`
  query service($slug: String!) {
    service: contentfulService(slug: {eq: $slug}) {
      title
      slug
      sections {
        ... on ContentfulHero {
          __typename
          ...Hero
        }
        ... on ContentfulSection {
          __typename
          ...Section
        }
        ... on ContentfulClientsSection {
          __typename
          ...ClientsSection
          ...TestimonialsSection
        }
        ... on ContentfulInsightsSection {
          __typename
          ...InsightsSection
          ...BlogsSection
        }
        ... on ContentfulTextImage {
          __typename
          ...TextImage
        }
        ... on ContentfulIconList {
          __typename
          ...IconList
        }
        ... on ContentfulImageSection {
          __typename
          ...ImageSection
        }
        ... on ContentfulConnectorsSection {
          __typename
          ...ConnectorsSection
        }
        ... on ContentfulCtaSection {
          __typename
          ...CtaSection
        }
      }
      metaTitle
      metaDescription {
        childMarkdownRemark {
          html
        }
      }
      metaKeywords
    }
  }
`;
