import React from 'react';

import * as Styled from './ConnectorsSection.styles';
import SectionTitle from '../SectionTitle';
import {graphql} from 'gatsby';

import useConnectorsList from './useConnectorsList';
import Container from '../Container';

const ConnectorsSection = ({title, subtitle}) => {
  const connectors = useConnectorsList();

  return (
    <Styled.Wrapper>
      <Container>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          alignment="left"
        />
        <Styled.ConnectorList>
          {connectors.map((connector, key) => (
            <Styled.Connector key={key}>
              <Styled.ConnectorLogo>
                <img
                  src={connector.logo.file.url}
                  alt={connector.title}
                  loading="lazy"
                />
              </Styled.ConnectorLogo>
            </Styled.Connector>
          ))}
        </Styled.ConnectorList>
      </Container>
    </Styled.Wrapper>
  );
};

export const query = graphql`
  fragment ConnectorsSection on ContentfulConnectorsSection {
    connectorsSectionTitle: title
    connectorsSectionSubtitle: subtitle
  }
`;

export default ConnectorsSection;
